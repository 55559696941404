.logo {
    display: flex;
    /* justify-content: start; */
    /* align-items: center; */
    /* height: 100%;  */
    /* padding: 10px; */
    /* margin-bottom: 1em; */
  }
  
  .logo img {
    max-width: 75%;
    max-height: 150px;
    width: auto;
    height: auto;
    object-fit: contain; /* Ensure the image scales down to fit within the container */
  }
  