/* src/components/MarkdownRenderer.css */

.markdown-container {
    /* Optional: Add some padding and set a max width */
    padding: 1rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .markdown-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }
  
  .markdown-container th,
  .markdown-container td {
    border: 1px solid #ddd;
    padding: 0.5rem;
    text-align: left;
  }
  
  .markdown-container th {
    background-color: #f4f4f4;
  }
  
  .markdown-container tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .markdown-container tr:hover {
    background-color: #f1f1f1;
  }
  
  .reference-link{
    background-color: #c0cfd6;
    padding: 0.125em 0.25em ;
    border-radius: 0.5em;
    font-size: small;
    cursor: pointer;
  }