.document-viewer {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 50%;
  border-right: 1px solid #e0e0e0;
  overflow: hidden;


}

.tabs-container {
  width: 100%;
  height: 2.261em;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  background-color: #e0e0e0;
}

.tabs-container::-webkit-scrollbar {
  display: none;
}

.tabs {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  height: 100%;
  white-space: nowrap;
}

.tab {
  position: relative;
  background-color: #e0e0e0;
  color: unset;
  border-radius: unset;
  max-width: 15em;
  padding: 0.5rem 1rem;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 0 auto;
  text-transform: capitalize;
  /* Ensure consistent sizing */
  box-sizing: border-box;
}


.tab:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 25%; /* Adjust to control the vertical positioning */
  height: 50%; /* Adjust to control the border height */
  width: 1px;
  background-color: #ccc; /* Adjust color as needed */
}

.tab.active::after {
  height: 0%;
}
.tab.active {
  background-color: #ffffff;
  border: 0.1em solid #cacaca;
  border-top: none;
  border-bottom: none;
}

.tab:hover {
  background-color: #fff;
}


.pdf-container {
  flex: 1;
  overflow: auto;
}

/* Override react-pdf-viewer styles */
.rpv-core__viewer {
  height: 100% !important;
}

.rpv-core__inner-page {
  margin: 0 auto;
}

.custom-toolbar{
  display: flex;
  flex-direction: row;
  width: 100%;

}

.left-section{
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;

  /* border: 1px solid red; */
  
}

.right-section{
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
}


.rpv-core__toolbar,
.rpv-default-layout__toolbar {
  background-color: #ffffff; /* Change this to your desired color */
  /* color: #fff; */
}

.rpv-default-layout__sidebar, .rpv-default-layout__sidebar-tabs, .rpv-default-layout__sidebar-headers {
  background-color: #fff; /* Change to your preferred color */
  color: #fff;
  border-color: #ececec;
}

.rpv-default-layout__container{
  border: none;
}



/*scrollbar*/

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}


