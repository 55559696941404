.modal{
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); 
    backdrop-filter: blur(10px); /* Adds the blur effect */
    z-index: 1000; /* Ensure it's on top of other elements */
  }