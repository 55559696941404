.video-player {

  overflow: hidden;

  display: flex;
  flex-direction: column;
  flex: 1;
}

.video-responsive {
  flex: 1;
}

.video-responsive iframe {
  
  width: 100%;
  
}


.display-transcript {

  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  overflow-y: auto;


}

.transcript-item {
  display: flex;
  flex-direction: row;
  gap: 1em;
  cursor: pointer;
  transition: 0.5s;
  padding: 0.5em;
  border-radius: 0.25em;
  font-size: 1.25em;
  text-transform: capitalize;
}

.transcript-item.active {
  background-color: #eeeeee;
 
}

.transcript-item:hover {
  background-color: #eeeeee;
}

.timestamp{
  color: rgb(77, 77, 153);
}