/* src/Login.css */
*,
*::before,
*::after {
  box-sizing: border-box;
  
}


.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
  
}



.central-container{
  /* border: 1px solid red; */
  display: flex;
  height: 70%;
  width: 100%;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  gap: 2em;
  background-color: none;
}

.brand{
  font-size: 1.25em;
  color: #ffae00;
}

.right{
  /* border: 1px solid blue; */
  flex: 4;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire container */
  object-position: center; /* Centers the image within the container */
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2em;
  border-radius: 10px;
  background-color: none;
  box-shadow: none;

  box-sizing: border-box;

}

.input-container {
  position: relative;
  width: 100%; /* Set this to the desired width */

}

.input-field {
  width: 100%;
  padding: 10px 0; /* Adjust padding as needed */
  border: none;
  border-bottom: 2px solid #ccc;
  background-color: transparent;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;
}


.input-label {
  position: absolute;
  top: 50%;
 
  left: 0px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #666;
  pointer-events: none;
  transition: all 0.25s ease;
}

.input-field:focus ~ .input-label,
.input-field:not(:placeholder-shown) ~ .input-label {
  top: 0;
  font-size: 12px;
  color: #ffae00;  /* Or any color you prefer */
}



.input-field:focus {
  border-bottom-color: #ffae00; /* Highlight color when focused */
}


.options-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.remember-me {
  display: flex;
  align-items: center;
}

button {
  padding: 10px;
  background-color: #ffae00;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}



.line{
  flex: 1;
  height: 1px;
  background-color: #ccc;
  margin: 0.5em 0;
}

.social{
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
}

.google-signin-button{
  width: 100%;
}

.redirect-btn{
  font-weight: 700;
  cursor: pointer;
  transition: 0.125s;
}

.redirect-btn:hover{
  color: #ffae00;

}


.error-box {
  position: fixed;
  top: 20px;
  left: -300px; /* Initial position (off-screen) */
  padding: 10px 20px;
  background-color: #ff4d4d; /* Red background for error */
  color: white;
  border-radius: 5px;
  font-size: 14px;
  animation: slideIn 0.5s forwards, slideOut 0.5s 4s forwards;
  z-index: 9999;
}

@keyframes slideIn {
  0% {
    left: -300px;
  }
  100% {
    left: 20px;
  }
}


@keyframes slideOut {
  /* 0% {
    right: -300px;
  } */
  100% {
    left: -300px;
  }
}