/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */

.auth-container {

    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: #ffffff;
    /* Set the height of the container */
    line-height: 40px;


}

.inner-container {
    display: flex;
    flex-direction: column;
    gap: 2em;
    width: 60%;
}

.auth-title{
    display: flex;
    flex-direction: column;
}
.auth-title h2{
    margin: 0;
    margin-bottom: 0.125em; 
    font-size: 2em;
  }
  

.left {
    flex: 3;
    /* Takes 3/7ths of the width */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* Ensure it fills the container height */

}

.right {
    flex: 4;
    /* Takes 4/7ths of the width */
    height: 100%;
    /* Ensure it fills the container height */

}

@media (max-width: 800px) {
    .right {
        flex: 0;
        display: none !important;
    }

    .right {
        background-color: lightgray; /* For debugging */
        flex: 0;
        display: none;
    }

    .left{
        flex: 1;
        /* justify-content: flex-start; */
        margin-top: 1em;
        align-items: flex-start;

        
    }


    .auth-container{
        overflow-y: auto;
        margin-bottom: 2em;
    }
    .inner-container{
        width: 80%;
        gap: 1em;
        /* border: 1px solid red; */
    }
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the entire container */
    object-position: center;
    /* Centers the image within the container */
}


.fade-enter {
    opacity: 0;
    /* transform: translateX(-100%); */
}

.fade-enter-active {
    opacity: 1;
    /* transform: translateX(0); */
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.fade-exit {
    opacity: 1;
    /* transform: translateX(0); */
}

.fade-exit-active {
    opacity: 0;
    /* transform: translateX(100%); */
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.auth-divider{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    text-transform: uppercase;
  }

  .rtr-error-box {
    position: fixed;
    top: 20px;
    left: -800px; /* Initial position (off-screen) */
    padding: 10px 20px;
    background-color: #ff4d4d; /* Red background for error */
    color: white;
    border-radius: 5px;
    font-size: 14px;
    animation: slideIn 0.5s forwards, slideOut 0.5s 3.5s forwards;
    z-index: 9999;
  }
  

  @keyframes slideIn {
    0% {
        left: -800px;
    }
    100% {
        left: 20px;
    }
}

@keyframes slideOut {
    0% {
        left: 20px; /* Start at the position where it was shown */
    }
    100% {
        left: -1000px /* Slide it back out to the left */
    }
}

.login-animation{
    scale:1.8;
    
}