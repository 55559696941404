/* Reset and base styles */
* {
    box-sizing: border-box;
   
}

body {
    margin: 0;
    
}

.qz-container {
    display: flex;
    flex-direction: column;
    flex: 1; 
    /* border: 1px solid blue; */
    height: 100%;
}

.qz-internal {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    /* border: 1px solid red; */
    height: 100%;
}

.qz-internal-header {
    padding: 1em;
    background-color: #f8f8f8;
    display: flex;
    /* justify-content: space-between; */
    flex-direction: row-reverse;
    align-items: center;
}

.qz-internal-header h1 {
    margin: 0;
}

.qz-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    position: relative;
    transition: all 0.5s ease;
   
}

.qz-sets {
    width: 100%;
    padding: 1em;
    background-color: #f9f9f9;
    overflow-y: auto;
    transition: width 0.5s ease;
}

.qz-sets .back-button {
    display: block;
    margin-bottom: 1em;
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
}

.simple-btn{
    display: block;
    margin-bottom: 1em;
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
    transition: 0.3s ease;
}

.simple-btn-test{
    margin-right: 10px;
    padding: 10px;
    border: none;
    background-color: #e0e0e0;

    border-radius: 4px;
    cursor: pointer;
    transition: 0.125s;

}
.simple-btn:hover{
    transform: translateY(-2px);
}

.btn-disabled{
    
    color: #949494;
}



.qz-sets .qz-sets-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;
}

.qz-sets-grid.single {
    grid-template-columns: repeat(2, 1fr);
    /* Switch to single-column layout when a set is selected */
}


@media (max-width: 1024px) {
    .qz-sets .qz-sets-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .qz-sets .qz-sets-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .qz-sets .qz-sets-grid {
        grid-template-columns: 1fr;
    }
}

/* Flashcard set item */
.qz-card-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1em;
    cursor: pointer;
    position: relative;
    text-align: center;
    transition: transform 0.3s ease;


    display: flex;
    flex-direction: column;
    max-height: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.qz-item-header{
    display: flex;
    flex-direction: row;

}

.qz-item-title{
    flex: 2;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;

    /* Add these properties */
    width: 100%;           /* Constrain width to parent */
    align-self: flex-start; /* Prevent stretching */
}

.qz-item-display{
    flex: 8;
}

.qz-card-item img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
}

.qz-card-item h3 {
    margin-top: 0.5em;
}

.qz-card-item:hover {
    transform: translateY(-5px);
}

.qz-card-item.active {
    border-color: #007bff;
}

/* Right column: Flashcards */
.qz-flashcards {
    width: 70%;
    overflow-y: auto;
    padding: 1em 10em ;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3em;
    background-color: #ffffff;
    position: absolute;
    right: -100%;
    top: 0;
    bottom: 0;
    transition: right 0.5s ease;
}

.qz-content.set-selected .qz-flashcards {
    right: 0;
}

.qz-content.set-selected .qz-sets {
    width: 30%;
}

.qz-content.animate .qz-sets {
    width: 30%;
}

.qz-content.animate .qz-flashcards {
    right: 0;
}

/* Flashcard flip styles */
.qz-set {
    padding: 2em;
    flex: 1;
    background-color: #fff;
    
}

.qz-set h2 {
    font-size: 32px;
    margin-bottom: 1.5em;
   
}
.qz-question{
    line-height: 40px;
}

.qz-options {
    margin: 1em 0;
}

.qz-option {
    margin-bottom: 1em;
    padding: 1em;
    /* background-color: #f0f0f0; */
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;

    font-size: 18px;
    border: 2px solid #f0f0f0; 
}

.qz-option.selected {
    /* border-color: 1px solid #007bff; */
    /* background-color: #007bff; */
    border-color: #007bff;
    /* color: white; */
}



/* Quiz navigation buttons */
.qz-navigation {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
}
/* Responsive adjustments */
@media (max-width: 768px) {
    .qz-content.set-selected .qz-sets {
        width: 100%;
        height: 50%;
    }

    .qz-flashcards {
        width: 100%;
        height: 50%;
        right: 0;
        bottom: -100%;
        top: auto;
        transition: bottom 0.5s ease;
    }

    .qz-content.set-selected .qz-flashcards {
        bottom: 0;
    }
}

.qz-result{
    /* border: 1px solid red; */
    width: 70%;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    overflow-y: auto;
    padding: 1em;
}



.qz-result .score-heading{
    
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    /* text-transform: uppercase; */
  
    

}

.qz-result .score-heading .simple-btn{
    margin: 0;
}


.qz-result h2{
    font-size: 24px;

}

.qz-result .score{
    
    font-size: 32px;
    color: #32923a;
}

.qz-result .correct{
    font-size: 1.25em;
}

.qz-result .separator{
    margin-top: 4em;
    width: 80%;
    height: 1px;
    /* transform: translateX(-50%); */
    /* transform: rotate(-20deg); */
    background-color: #949494;
    align-self: center;

}

.qz-result .qz-option{
    cursor: default;
}

.qz-result .qz-question{
    
    margin-bottom: 3em;
    border: 2px solid #f0f0f0; 
    padding: 2em;
    border-radius: 1em;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
   
}

.qz-result .qz-option.selected{
    border-color: #c00000;
}

.qz-result .qz-question h3{
    
    font-size: 1.75em;
}
.qz-result .qz-option.correct {
    /* border-color: 1px solid #007bff; */
    /* background-color: #007bff; */
    border-color: #32923a;
    /* color: white; */
}



.qz-result .total{
    font-size: 1.25em;
}