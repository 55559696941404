/* settings.css */

.settings-container {
    max-width: 800px;
    margin: 0 auto;
    

}

.settings-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.settings-layout {
    display: flex;
    gap: 20px;
}

.settings-sidebar {
    width: 200px;
}

.settings-content {
    flex: 1;
}
/* HTML: <div class="loader"></div> */
.loader {
    width: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid;
    border-color: #797979 #0000;
    animation: l1 1s infinite;
  }
  @keyframes l1 {to{transform: rotate(.5turn)}}

.button:disabled{
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none;
}

.nav-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    color: #000;
}

.nav-button:hover {
    background-color: #f0f0f0;
}

.nav-button.active {
    background-color: #e0e0e0;
    font-weight: bold;
}

.settings-section {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.input-field,
.select-field {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.switch-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 24px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.button-primary {
    background-color: #2196F3;
    color: white;
}

.button-primary:hover {
    background-color: #1976D2;
}

.button-outline {
    background-color: white;
    color: #2196F3;
    border: 1px solid #2196F3;
}

.button-outline:hover {
    background-color: #E3F2FD;
}

.button-danger {
    background-color: #F44336;
    color: white;
}

.button-danger:hover {
    background-color: #D32F2F;
}

.divider {
    margin: 20px 0;
    border: none;
    border-top: 1px solid #e0e0e0;
}