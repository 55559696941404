.fb-container {

    height: 100%;
    display: flex;
    flex-direction: column;
}

.fb-form {
    flex: 1;
    display: flex;
    

}




.fb-input {
    flex: 1;
    padding: 1em;
    outline: none;
    font-family: 'Inter', sans-serif;
    font-size: large;
    resize: none;
    border-radius: 0.75em;
}

.fb-btn {
    padding: 1em;
    border: none;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    transition: 0s;
    font-weight: 700;
    
}

.fb-btn:hover {
    background-color: #333;
}

.fb-btn.disabled {
    background-color: #666;
 
    pointer-events: none;
    cursor: not-allowed;
}   