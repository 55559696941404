  .chatbot {
    display: flex;
    flex-direction: column;
    /* border: 1px solid rebeccapurple; */
    /* min-width: 50%; */

  }

  .chatbot-loading{
    display: flex;
    /* border: 1px solid red; */
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .large{
    font-size: 16px;
  }

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: large;
    gap: 1em;
    padding: 1em;
  }

  .icon-btn {
    color: #646464;
    cursor: pointer;
    transition: 0.125s;
  }

  .icon-btn:hover {
    color: #000;
  }

  .chatbot-area {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    flex: 1;
    padding: 10px;
  }

  .messages-container {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 0 1em;
    max-width: 100%; /* Ensures it doesn't exceed its parent's width */

  }

  .ch-logo-container{
    /* border: 1px solid red; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ch-logo{
    /* border: 1px solid red; */
    align-self: center;
    opacity: 0.2;
    
    /* max-height: 23px; */
    max-width: 19em;

    /* border: 1px solid red; */
    /* position: ; */
  }

  .message {
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
    gap: 0.5em;


  }

  
  .sender{
    /* font-size : 1; */
    color: #646464;
    transform: translateY(-2.5px);
    font-weight: 700;
    /* text-decoration: underline dotted; */
  }

  .message-text {
    word-break: break-word;
    overflow-wrap: break-word;

  }

  .identifier-user {
    border: 1.5px solid #02025c;
    min-height: 1.5em;

  }

  .identifier-assistant {
    border: 1.5px solid #ffbb00;
    min-height: 1.5em;
  }




  .input-area {
    display: flex;
    align-items: center;
    /* border: 1px solid #ccc; */
    border-radius: 0.75em;
    padding: 0.5em;
    

    background-color: #F4F4F4;

  }

  .input-area input {
    flex: 1;
    padding: 10px;
    border: 1px solid #F4F4F4;
    border-radius: 4px;
    margin-right: 10px;
    outline: none;
    border: none;
    background-color: transparent;

  }



  .input-area button {
    padding: 10px 20px;
    border: none;
    background-color: #ffbb00;
    color: white;
    border-radius: 0.75em;
    cursor: pointer;
    transition: 0.25s;
  }

  .input-area button:hover {
    background-color: #8f6900;
  }



  .message .markdown-body {

    /* font-size: 16px; */
    /* overflow: hidden; */
  }


  .message-text{
    overflow: hidden;

  }

  .message-text  pre {
    background-color: #f6f8fa;
    border-radius: 3px;
    padding: 16px;
    overflow-x: auto;
    /* white-space: pre-wrap; */
    word-wrap: break-word;
  }
  
  .message-text .markdown-body code {
    background-color: rgba(27,31,35,0.05);
    border-radius: 3px;
    padding: 0.2em 0.4em;
    /* white-space: pre-wrap; */
    /* word-wrap: break-word; */
    overflow-x: auto;
  }

  .right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .image{
    /* border: 1px solid red; */
  }