@keyframes wiggle {
  0% { transform: rotate(0deg); }
  15% { transform: rotate(6deg); }
  30% { transform: rotate(-3deg); }
  45% { transform: rotate(6deg); }
  60% { transform: rotate(-8deg); }
  75% { transform: rotate(3deg); }
  100% { transform: rotate(0deg); }
}

.navigation-panel {
  /* flex: 0 0 7.5%; */
  display: flex;
  flex-direction: column;
  padding: 1.25em 0.25em 1.25em 0.5em ;
  overflow-y: auto; /* Allow internal scrolling */
  border-right: 1px solid #e0e0e0;
  overflow-x: hidden ;
  height: 100%;
  font-size: 16px;

  gap: 2em;

}


.logo-and-toggle{
  /* border: 1px solid blue; */
  display: flex;
  /* flex: 0 0 5%; */
  /* justify-content: space-between; */
  align-items: center;
  justify-content: space-between;
  padding: 0 .5em ;
}


.workflow-and-new{

  flex: 2;
  display: flex;
  flex-direction: column;
  min-height: 40%;
  max-height: 40%;
  gap: 1em;
}





.workflows {
  flex: 1;
  overflow-y: auto; /* Introduce a scrollbar if the content overflows */

  scrollbar-gutter: stable;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  display: flex;
  flex-direction: column;
  gap: 0.5em;



}

.workflows > ul > li {
  /* margin-bottom: 1.125em; */
}

.workflows::-webkit-scrollbar {
  display: none; /* WebKit browsers */
}

.navigation-items {
  border-top: 1px solid #e0e0e0;
  padding-top: 1em;
}



.navigation-items, .settings {
  flex: 1; /* Ensure these sections can grow/shrink */
  overflow-y: auto; /* Allow scrolling within sections */
}



.workflows ul, .navigation-items ul, .settings ul {
  list-style-type: none;
  padding: 0;
  margin: 0; /* Reset default margin */
}


.add-doc{
  margin-right: 0.25em;
  cursor: pointer;
}

.add-doc:hover{
  color: #000000;
  font-weight: 500;
}

.nav-itm {
  display: flex;

  cursor: pointer;
  /* padding: 10px 0 0 0; */
  /* background-color: #e0e0e0; */
  /* margin: 0.75em 0; */
  border-radius: 0.75em;
  display: flex;
  align-items: center; /* Center align items vertically */
  gap: 0.5em;
  max-width: 100%; /* Ensure it does not exceed container width */
  white-space: nowrap; /* Prevent text from wrapping */
  /* overflow: hidden; */
  text-overflow: ellipsis; /* Show ellipsis for overflow text */

  border-radius: 0.25em;
  padding: 0.5em 0.5em;

  transition: 0.3s ease-out;
}

.wf-selected{
  background-color: #f0f0f0;
  font-weight: 500;
}

.nav-itm:hover {
  background-color: #f0f0f0;
  font-weight: 500;

}


.workflow {
  display: flex;
  flex-direction: row;
  gap: 0.25em;
  transition: 0.125s;
}

.nav-itm-itm{
  transition: 0.3s ease-in-out; 

}

.navigation-panel li:hover .fa-bars-staggered {
  /* animation: wiggle 0.5s ease-in-out; */

}

.nv-icon{
  box-sizing: border-box;
  border-left: 1px solid #ffffff;
}

.navigation-panel li:hover .nv-icon{
  
  border-left: 1px solid #9c9c9c;
}

.nv-icon{
  padding-left: 0.5em;
}




.rotated {
  transform: rotate(90deg); /* Adjust the rotation angle as needed */
}


.nav-itm-itm:hover {
  color: #000000;
  font-weight: 500;
  
 
}

.inner-itm {
  margin: 0;
  margin-left: 2em;
}

.nav-text {
  flex: 1; /* Allow text to take up remaining space */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis for overflow text */
  white-space: nowrap; /* Prevent text from wrapping */
}

.workflows > ul > li > ul {
  padding-left: 20px;
}

.inner-itm{
  /* margin-bottom: 0.75em; */
}




.settings{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.soon{
  color: #505050;
  font-size: smaller;
}




.list-enter {
  max-height: 0;
  opacity: 0;
}

.list-enter-active {
  max-height: 1000px; /* Adjust this value based on your needs */
  opacity: 1;
  transition: all 200ms ease-in;
}

.list-exit {
  max-height: 1000px;
  opacity: 1;
}

.list-exit-active {
  max-height: 0;
  opacity: 0;
  transition: all 200ms ease-in;
}

.document-list {
  overflow: hidden;
}

.wf-menu-container{
  position: relative;
  overflow: visible; 
}

.wf-menu-btn{
  /* border: 1px solid red; */
  padding: 0em 0.5em;
 
  border-radius: 0.25em;
  transition: 0.3s;
}

.wf-menu-btn:hover{
  background-color: #e7e7e7;
}

.wf-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0.75em;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  top: 100%; /* Position it below the button */
  right: 0; /* Align it to the right */
  width: 120px; /* Adjust width as needed */
  display: flex;

}

.wf-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  /* border: 1px solid red; */
  width: 100%;
  overflow: hidden;
  border-radius: 0.75em;
}

.wf-menu ul li {
  padding: 8px 16px;
  cursor: pointer;
}

.wf-menu ul li:hover {
  background-color: #f0f0f0;
}


.menu-transition-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.menu-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease, transform 300ms ease;
}

/* Exiting state */
.menu-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.menu-transition-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms ease, transform 300ms ease;
} 
/* Ensure the button wrapper has a relative position to serve as a reference point for absolute positioning */


.menu-btn-transition-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.menu-btn-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease, transform 300ms ease;
}

/* Exiting state */
.menu-btn-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.menu-btn-transition-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms ease, transform 300ms ease;
} 


.wf-rename{
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  border-bottom: 1px dotted black;
}

.wf-rename-confirm{
  padding: 0 0.5em;
  transition: 0.3s;
  border-radius: 0.25em ;
}

.wf-rename-confirm:hover{
  background-color: #e7e7e7;
}

.confirm-delete{
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
}

.wf-delete-controls{
  display: flex;
  flex-direction: row;
  gap: 0.25em;
}

.wf-delete-control{
  font-size: smaller;
  padding: 0.125em;
  border-radius: 0.25em;
  transition: 0.3s;

}

.wf-delete-control:hover{
  background-color: #e7e7e7;
}