
*{
    box-sizing: border-box;
}
.up-upload-form {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1em;
    padding: 2em 0 ;
    height: 100%;
    overflow: hidden;
}

.upload-area {
    flex: 2;
    display: flex;
}

.upload-btn {
    border: 1px dashed rgb(71, 71, 71);
    border-radius: 4px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;

}

.upload-btn:hover {
    font-size: 1.5em;
}

.staging-area {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 1em;
    overflow-y: auto;
}

.staged-file {
    display: flex;
    gap: 2em;
    padding: 0.5em;
    border: 1px dashed rgb(71, 71, 71);
    border-radius: 4px;
    align-items: center;
    position: relative;
    /* Add relative positioning */
}

.staged-file .file-icon {
    font-size: 2em;
}

.file-info {
    flex: 1;
    display: flex;
    gap: 0.5em;
    flex-direction: column;
}

.file-info .filename {
    font-size: 1.5em;
    font-weight: 700;
}

.file-info progress {
    width: 100%;
    height: 10px;
}

.remove-btn {
    position: absolute;
    /* Position the remove button absolutely */
    top: 0;
    /* Align it to the top */
    right: 0;
    /* Align it to the right */
    background: transparent;
    border: none;
    color: #616161;
    cursor: pointer;
    font-size: 1.5em;
    /* Adjust font size for better visibility */
    transition: 0.25s;
}

.remove-btn:hover {
    color: #2c2c2c;
}

.btn{
    color: black;
    display: flex;
    justify-content: center;
    gap: 0.5em;
    transition: 0.25s;
}

.btn.loading{
    pointer-events: none;
    background-color: #969696;
}

.btn:hover {
    background-color: #616161;
    color: #ffffff;
    font-weight: 500;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid;
    border-color: #797979 #0000;
    animation: l1 1s infuploade;
  }
  @keyframes l1 {to{transform: rotate(.5turn)}}


  .upload-link {
    flex: 1;
    display: flex;
    flex-direction: row;
    
    align-items: center;
    /* border: 1px solid red;; */
    border: 2px dotted #ddd;
    border-radius: 1rem;
    align-items: stretch;
  }
  
  .upload-link .submit{
    /* border: 1px solid blue; */
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    color: white;
    font-size: 1rem;
    border-radius: 0 1rem 1rem 0;
    font-weight: 700;
    cursor: pointer;
  
  }
  .upload-link .submit .submit-text{
    transition: 0.3s ease-in-out;
  }
  
  .upload-link .submit:hover .submit-text{
    transform: translateY(-2px);
  
  }
  
  .upload-link input {
    
    flex: 8;
    font-size: 1.5rem;
    outline: none;
    
    border: none;
    
    padding: 0em 1em;
  }
  