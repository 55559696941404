.flashcard-upload-container{
   /* border: 1px solid red; */
   height: 100%;
   display: flex;
   flex-direction: column;
   /* gap: 1em; */
}

.document-list{
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    flex: 1;
    overflow: auto;
}

.existing-document {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    
}

.existing-document.selected {
    background-color: #e6f7ff;
    border-color: #1890ff;
}

.document-checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid #1890ff;
    border-radius: 4px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox-icon {
    color: #1890ff;
    font-weight: bold;
}

.document-info {
    flex-grow: 1;
}

.document-title {
    font-weight: bold;
}

.document-description {
    font-size: 0.9em;
    color: #666;
}

.create-btn{
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #1890ff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    display: flex;
    gap: 1em;

    
}



.create-btn:hover {
    background-color: #40a9ff;
}

.create-btn.loading{
    pointer-events: none;
    background-color: #969696;
}


/* HTML: <div class="loader"></div> */
.loader {
    width: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid;
    border-color: #797979 #0000;
    animation: l1 1s infinite;
  }
  @keyframes l1 {to{transform: rotate(.5turn)}}