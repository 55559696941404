/* Reset and base styles */
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.flc-container {
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    flex: 1; 
    height: 100%;
}

.flc-empty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    /* border: solid red; */
}

.flc-empty div{
    width: 10em;
    height: 3em;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.flc-internal {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.flc-internal-header {
    padding: 1em;
    background-color: #f8f8f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flc-internal-header h1 {
    margin: 0;
}

.flc-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    position: relative;
    transition: all 0.5s ease;
}

.flc-sets {
    width: 100%;
    padding: 1em;
    background-color: #f9f9f9;
    overflow-y: auto;
    transition: width 0.5s ease;
}

.flc-sets .back-button {
    display: block;
    margin-bottom: 1em;
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
}

.flc-sets .flc-sets-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;
}

.flc-sets-grid.single {
    grid-template-columns: repeat(2, 1fr);
    /* Switch to single-column layout when a set is selected */
}


@media (max-width: 1024px) {
    .flc-sets .flc-sets-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .flc-sets .flc-sets-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .flc-sets .flc-sets-grid {
        grid-template-columns: 1fr;
    }
}

/* Flashcard set item */
.flc-flashcard-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1em;
    cursor: pointer;
    position: relative;
    text-align: center;
    transition: transform 0.3s ease;

    display: flex;
    flex-direction: column;
    max-height: 250px;
    overflow: hidden;
    text-overflow: ellipsis;

  
    
}

.wf-rename{
    margin: .83em 0 ;
}

.flc-flashcard-item .flc-item-title{
    flex: 2;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;

    /* Add these properties */
    width: 100%;           /* Constrain width to parent */
    align-self: flex-start; /* Prevent stretching */
}

.flc-flashcard-item .flc-item-display{
    flex: 8;
}

.flc-item-header{
    display: flex;
    flex-direction: row;
}

.flc-flashcard-item img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
}

.flc-flashcard-item h3 {
    margin-top: 0.5em;
}

.flc-flashcard-item:hover {
    transform: translateY(-5px);
}

.flc-flashcard-item.active {
    border-color: #007bff;
}

/* Right column: Flashcards */
.flc-flashcards {
    width: 70%;
    overflow-y: auto;

    background-color: #ffffff;
    position: absolute;
    right: -100%;
    top: 0;
    bottom: 0;
    transition: right 0.5s ease;

    display: flex;
    flex-direction: column;

    /* border: 1px solid red; */

    
}

.flc-flashcards-display{

    width: 100%;
  
    padding: 1em 10em ;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3em;
    background-color: #ffffff;
    /* position: absolute; */
    flex:1;
   
}

.load-more{
    flex: 1;
    /* border: 1px solid blue; */

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em 0;
    font-size: 4em;

}

.load-more-btn{
    transition: 0.25s;
    cursor: pointer;
}

.load-more-btn:hover{
    transform: translateY(2px);
    opacity: 0.8;
}


.flc-content.set-selected .flc-flashcards {
    right: 0;
}

.flc-content.set-selected .flc-sets {
    width: 30%;
}

.flc-content.animate .flc-sets {
    width: 30%;
}

.flc-content.animate .flc-flashcards {
    right: 0;
}

/* Flashcard flip styles */
.flashcard {
    perspective: 2000px;
    cursor: pointer;
    transition: 0.25s;
}

.flashcard-inner {
    position: relative;
    width: 100%;
    min-height: 100px;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flashcard.flipped .flashcard-inner {
    transform: rotateY(180deg);
}

.flashcard-front,
.flashcard-back {
    position: absolute;
    width: 100%;
    height: 300px;
    backface-visibility: hidden;
    padding: 2em;
    box-sizing: border-box;
    
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.3s ease;
}

.flashcard:hover {
    transform: translateY(5px);
}


.flashcard-inner,
.flashcard-front,
.flashcard-back {
    height: 200px;
   
}

.flashcard-front {
    background-color: #fff;
    border: 1px solid #ffb13c;
    font-weight: bold;
    font-size: larger;
    padding: 4em;
}

.flashcard-back {
    /* background-color: #f0f0f0; */
    transform: rotateY(180deg);
    border: 1px solid #007bff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .flc-content.set-selected .flc-sets {
        width: 100%;
        height: 50%;
    }

    .flc-flashcards {
        width: 100%;
        height: 50%;
        right: 0;
        bottom: -100%;
        top: auto;
        transition: bottom 0.5s ease;
    }

    .flc-content.set-selected .flc-flashcards {
        bottom: 0;
    }
}


.item-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 0.75em;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
    top: 100%; /* Position it below the button */
    right: 0; /* Align it to the right */
    width: 120px; /* Adjust width as needed */
    display: flex;
    transform: translateY(-10px);
  
  }
  
  .item-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    /* border: 1px solid red; */
    width: 100%;
    overflow: hidden;
    border-radius: 0.75em;
  }
  
  .item-menu ul li {
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .item-menu ul li:hover {
    background-color: #f0f0f0;
  }