.founders{
    display: flex;
    flex-direction: column;

    justify-content: space-around;

    
    margin: 1em;
    flex: 1;
    
    
    
    
}

h1{
    font-weight: 400;
    font-size: 25px;
}
.cap{
    font-weight: 700;
    position: relative;
    display: inline-block;
    font-size: 32px;
    text-transform: capitalize;
}

.cap::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px; /* Adjust the distance below the text */
    width: 100%;
    height: 3px; /* Thickness of the underline */
    background-color: #ffbb00;
   
}

.team{
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
    align-items: center;
    justify-content: space-between;
 
    flex: 1;

}

.founder{
    margin: 1em 0;
    gap: 1em;
    display: flex;
    flex-direction: column;
    color: rgb(0, 0, 32);
}
.bold-name{
    font-weight: 700;

}

.contacts{
    transform: translateX(-5px);
    display: flex;
    flex-direction: row;
    gap: 0.25em;
    /* justify-content: space-between; */
    /* padding: 0 0.5em; */
}


.social-icon{
    height: 50x;
    width: 50px;
    cursor: pointer;
    transition: 0.5s;
}

.social-icon:hover{
    transform: translateY(-2px);
}



.icon{
    /* border: 3px solid; */
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
    border-radius: 0 0 1em 0;
    object-fit: cover;
    width: 200px;
    height: 300px;
}

