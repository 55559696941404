/* HTML: <div class="loader-pulse"></div> */
.file-loading {
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    flex: 1;
    align-items: center;
    justify-content: center;
}


/* HTML: <div class="loader-pulse"></div> */
/* HTML: <div class="loader"></div> */
