.notes-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* border-left: 1px solid #ccc; */
   
    padding: 10px;
    overflow-y: hidden; /* Prevent outer container scrolling */
  }
  
  .custom-quill .ql-container {
    border: none; /* Remove border around the container */
  }
  
  .custom-quill .ql-editor {
    flex: 1;
    min-height: 0; /* Ensure it can shrink */
    max-height: 100%; /* Ensure it can grow */
    overflow-y: auto; /* Ensure content can scroll */
    border: none; /* Remove border around the editor */
    padding: 10px; /* Optional: Add padding if needed */
  }
  
  .custom-quill .ql-toolbar {
    flex: 0 0 auto; 
    border: none; 
  }
  