.notes-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* border-left: 1px solid #ccc; */
   
    padding: 10px;
    overflow-y: hidden; /* Prevent outer container scrolling */
  }
.pc-item{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5em;
    border-radius: 1em;
    padding: 0.5em 1em;
    cursor: pointer;
    transition: 0.3s;

    font-size: 16px;
}

.pc-item:hover{
   
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
}


/* Podcast.css */

/* Container for the podcast component */
.podcast-container {
    position: relative;
    overflow: hidden;
  }
  
  /* Audio player container */
  .audio-player-container {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%; /* Adjust the width as needed */
    max-width: 80%; /* Max width for the player */
    background-color: #fff;
    border-radius: 1em;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
    z-index: 1000;
  }
  
  /* Transition classes */
  .audio-player-transition-enter {
    transform: translateX(-50%) translateY(130%);
  }
  
  .audio-player-transition-enter-active {
    transform: translateX(-50%) translateY(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .audio-player-transition-exit {
    transform: translateX(-50%) translateY(0);
  }
  
  .audio-player-transition-exit-active {
    transform: translateX(-50%) translateY(130%);
    transition: transform 0.3s ease-in-out;
  }
  
  /* Audio player header */
  .audio-player-header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0.5em 1em;
 
    /* border-bottom: 1px solid #ddd; */
    border-radius: 12px 12px 0 0;
  }
  
 
  
  /* Close button */
  .audio-player-close {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #888;
  }
  
  .audio-player-close:hover {
    color: #000;
  }
  
  /* Custom styles for the audio player */
  .rhap_container {
    background-color: transparent !important;
    box-shadow: none !important;
    border-radius: 0 0 12px 12px;
    /* border: 1px solid red;; */
    /* display: flex; */
    /* align-items: center; */
    width: 100%;

  }

  .rhap_main-controls{
    flex: 1;
    /* border: 1px solid blue; */
  }
  
  .rhap_main-controls-button,
  .rhap_volume-button,
  .rhap_repeat-button {
    color: #333 !important;
  }
  .rhap_main-controls-button:hover,
  .rhap_volume-button:hover,
  .rhap_repeat-button:hover {
    /* color: #333 !important; */
    background-color: transparent !important;
  }


  .rhap_progress-indicator {
    /* background-color: #333 !important; */
  }
  
  .rhap_progress-filled {
    /* background-color: #555 !important; */
  }
  
  .rhap_progress-container {
    /* background-color: #e0e0e0 !important; */
    width: 60%;
  }

  
  /* Time labels */
  .rhap_time {
    width: 50px; /* Fixed width for time labels */
    font-size: 16px;
    color: #333;
  }

  
  
  .pc-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px;
  }
  
  .pc-item:hover {
    background-color: #f5f5f5;
  }
  

  
  .loader {
    width: 24px;
    height: 24px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #333;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 10px;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  





