.auth-submit{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.25em;
}

test{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.25em;  
}

