/* CSS for the VerifyEmail component */
.verify-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  .spinner {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }
  
  /* Animation for the spinner */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loading-text {
    font-size: 18px;
    color: #555;
  }
  
  .success-text {
    font-size: 18px;
    color: #28a745; /* Success Green */
  }
  
  .error-text {
    font-size: 18px;
    color: #e74c3c; /* Error Red */
  }
  