/* Main container for the welcome page */
.welcome-page {
  flex: 1;



  width: 100%;

  display: flex;
  flex-direction: column;

  overflow: hidden;
  height: 100%;
  /* border: 1px solid blue; */
  /* align-items: center; */
  /* padding-bottom: 3em; */
}

.welcome-page .top-bar {
  width: 100%;
  ;
}

.welcome-page h1 {
  font-size: 32px;
  color: #111;
}

.welcome-page p {
  font-size: 16px;
  color: #666;
}


.init-file {
  /* border: 1px solid red; */
  /* height: 100%; */
  flex: 1;
  /* padding: 0 39em; */
  overflow: hidden;
  /* border: 1px solid red; */

  display: flex;
  flex-direction: row;
  gap: 1em;



}

.init-main-area-container{
  display: flex;
  /* border: 1px solid magenta; */
  flex: 1;
  justify-content: center;
  align-items: center;
}

.init-main-area {
  /* padding: 10em 20em; */
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  flex: 1;
  
  overflow: hidden;
  height:70%;
  max-width: 70%;
  
  /* align-items: center; */
 text-align: center;
}




.action-container {
  display: flex;
  flex-direction: column;

  justify-content: center;
  flex: 1;

  /* margin-top: 40px; */
  overflow: hidden;
}

.action-box {
  flex: 10;
  border: 2px dotted #ddd;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 1rem;

  /* border: 1px solid blue; */
}

.init-divider {
  display: flex;
  flex-direction: row;
  /* border: 1px solid red; */

  align-items: center;
  gap: 1em;
  flex: 1;
  /* border: 1px solid red;; */
  padding: 0em 1em;
}

.init-line {

  height: 1px;
  width: 25%;
  background-color: #c0c0c0;
  flex: 1;

}

.init-link {
  flex: 1;
  display: flex;
  flex-direction: row;

  align-items: center;
  /* border: 1px solid red;; */

  align-items: stretch;
}



.init-link .submit .submit-text {
  transition: 0.3s ease-in-out;
}

.init-link .submit:hover .submit-text {
  transform: translateY(-2px);

}

.init-link input {

  flex: 8;
  font-size: 1.5rem;
  outline: none;
  
  border: none;
  /* border: 1px solid; */
  overflow: hidden;

  padding: 0em 1em;
  min-width: 0;
  border: 2px dotted #ddd;
  border-right: none;
    
  border-radius: 1rem 0rem 0rem 1rem;
}

.init-link .submit {
  /* border: 1px solid blue; */
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  color: white;
  font-size: 1rem;
  border-radius: 0 1rem 1rem 0;
  font-weight: 700;
  cursor: pointer;
  
  /* min-width: 100px; */

}

.action-box:hover {
  background-color: #f1f1f1;
}

.import-box {
  position: relative;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-info {
  margin-top: 20px;
}



.init-staging-area {

  text-align: left;
  flex: 1;
  overflow: auto;


  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  border: 1px solid #ddd;
  overflow: hidden;

}

.staging-files {
  flex: 18;
  /* border: 1px solid red; */
  overflow-y: auto;

}

.empty-staging {
  display: flex;
  justify-content: center;
  align-items: center;
}


.staged-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: unset;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #ffffff;
}

.staged-file:hover {

  background-color: #e6e6e6;
}


.file-icon {
  font-size: 24px;
  margin-right: 10px;
}

.file-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.filename {
  font-weight: bold;
}

progress {
  width: 100%;
  margin-top: 5px;
}

.remove-btn {
  background: none;
  border: none;
  color: #6e6e6e;
  cursor: pointer;
  margin: 0.5em 0.5em 0 0;
}

.remove-btn:hover {
  color: #272727;
  background-color: none;

}

.init-upload-btn {
  /* margin-top: 20px; */
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 0.25em;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  /* border: 1px solid red; */
  /* align-self: flex-end; */

}

.init-upload-btn.disabled {
  background-color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
}


.empty-animation {
  height: auto;
  width: 100%;
  /* border: 1px solid blue; */


}