/* NotAuthorizedPage.css */

/* Container Styling */
.not-authorized-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    padding: 0 20px;
    background-color: #ffffff; /* White background */
    color: #333333; /* Dark grey text */
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Sans-serif font */
    text-align: center;
    box-sizing: border-box;
  }
  
  /* Heading Styling */
  .not-authorized-heading {
    font-size: 3rem;
    margin-bottom: 1rem;
    line-height: 1.2;
    font-weight: 700;
  }
  
  /* Message Styling */
  .not-authorized-message {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    max-width: 600px;
    line-height: 1.5;
  }
  
  /* Button Styling */
  .not-authorized-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: #007BFF; /* Blue background */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .not-authorized-button:hover,
  .not-authorized-button:focus {
    background-color: #0056b3; /* Darker blue on hover/focus */
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .not-authorized-heading {
      font-size: 2.5rem;
    }
  
    .not-authorized-message {
      font-size: 1rem;
    }
  
    .not-authorized-button {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  }
  