/* NotFoundPage.css */

/* CSS Variables for Easy Theming */
:root {
    --color-background: #ffffff;
    --color-text: #333333;
    --color-primary: #dc3545; /* Red for error indication */
    --color-primary-hover: #a71d2a;
    --font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  /* Container Styling */
  .not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    padding: 0 20px;
    background-color: var(--color-background);
    color: var(--color-text);
    font-family: var(--font-family);
    text-align: center;
    box-sizing: border-box;
  }
  
  /* Heading Styling */
  .not-found-heading {
    font-size: 3rem;
    margin-bottom: 1rem;
    line-height: 1.2;
    font-weight: 700;
  }
  
  /* Message Styling */
  .not-found-message {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    max-width: 600px;
    line-height: 1.5;
  }
  
  /* Button Styling */
  .not-found-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: var(--color-primary);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .not-found-button:hover,
  .not-found-button:focus {
    background-color: var(--color-primary-hover);
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .not-found-heading {
      font-size: 2.5rem;
    }
  
    .not-found-message {
      font-size: 1rem;
    }
  
    .not-found-button {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  }
  