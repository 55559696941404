
.app {
  display: flex;
  height: 100vh; /* Use viewport height */
  overflow: hidden; /* Prevent the app from scrolling */

  font-size: 14px;
  /* font-family: 'Inter', sans-serif; */
  /* letter-spacing: 0.5px; */
}

/* Keyframes for modal animation */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
}

/* Modal styles */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  background: white;
  padding: 20px;
  z-index: 1000;
  border: 1px solid #ccc;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  animation: modalFadeIn 0.3s forwards;

  border-radius: 1em;
  height: 600px;
  width: 800px;
}

.modal--after-open {
  animation: modalFadeIn 0.3s forwards;
}

.modal--before-close {
  animation: modalFadeOut 0.3s forwards;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
}



.main-app {
 
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  /* border: 1px solid magenta; */

}


.open-dyslexic-font {
  font-family: 'Open-Dyslexic'
}


.loader-pulse {
  width: 75px;

  aspect-ratio: 0.75;
  --c:no-repeat repeating-linear-gradient(90deg,#e1e1e1 0 20%,#0000 0 40%);
  background: var(--c),var(--c),var(--c),var(--c);
  background-size: 100% 26%;
  animation: l22 1.5s infinite;
}

@keyframes l22 {
 0%    {background-position:0 -20px        ,0 -20px         ,0 -20px         ,0 -20px}
 12.5% {background-position:0 -20px        ,0 -20px         ,0 -20px         ,0 calc(3*100%/3)}
 25%   {background-position:0 -20px        ,0 -20px         ,0 calc(2*100%/3),0 calc(3*100%/3)}
 37.5% {background-position:0 -20px        ,0 calc(1*100%/3),0 calc(2*100%/3),0 calc(3*100%/3)}
 45%,
 50%   {background-position:0 calc(0*100%/3),0 calc(1*100%/3),0 calc(2*100%/3),0 calc(3*100%/3)}
 62.5% {background-position:0 calc(0*100%/3),0 calc(1*100%/3),0 calc(2*100%/3),0 50px}
 75%   {background-position:0 calc(0*100%/3),0 calc(1*100%/3),0 50px          ,0 50px}
 87.5% {background-position:0 calc(0*100%/3),0 50px          ,0 50px          ,0 50px}
 100%  {background-position:0 50px          ,0 50px          ,0 50px          ,0 50px}
}