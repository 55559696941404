/* Main container styling */
.main-view {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex: 1;
  width: 100%;
 
}


/* Top bar styling */
.top-bar {
  /* Your existing styles */
}

/* Bottom section with Flexbox */
.bottom-section {
  display: flex;
  flex: 1;
  position: relative;
  overflow: hidden; /* To hide the sliding NotesSection */
  min-width: 0;
}

/* Flex basis for components */
.flex-half {
  overflow: hidden;
  flex: 0 0 50%;
  transition: flex 0.3s ease;
  min-width: 50%;
}

.flex-third {
  flex: 1 1 33.333%;
  transition: flex 0.3s ease;
}

/* NotesSection initial state (hidden) */
.notes-section {
  width: 33.333%;
  height: 100%;
  transition: transform 0.3s ease;
}

/* CSSTransition classes for sliding in/out */
.slide-enter {
  transform: translateX(-100%);
}

.slide-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.slide-exit {
  transform: translateX(100%);
}

.slide-exit-active {
  /* transform: translateX(-100%); */
  transition: transform 300ms ease-in-out;
}

/* Ensure DocumentViewer and Chatbot are above NotesSection when sliding */
/* .document-viewer,
.chatbot {
  position: relative;
  z-index: 1;
} */
