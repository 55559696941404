body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Set height for the root elements */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  box-sizing: border-box;
  color: #000;
}

/* Add basic styling to make sure the app uses full height */
body {
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Ensure the body does not scroll */
  color: #424242;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}
