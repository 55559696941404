.doc-upload-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
}

.upload-section{
  /* border: 1px solid blue; */
  flex: 1;
}