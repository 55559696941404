.top-bar {


    flex: 0 0 5%;
    border-bottom: 1px solid #e0e0e0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 1em;


}
.btns{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;
}

.btn {
    
    padding: 0.75em 1.25em;
    border: none;
    background-color: #e0e0e0;

    border-radius: 0.75em;
    cursor: pointer;
    transition: 0.125s;
    font-weight: 700;
}

.btn:hover {
    color: #000;
    font-weight: 700;
 
}

.feedback{
    background-color: #000;
    color: #fff;
}

.feedback:hover{

    color: #fff;
}

.title{
    display: flex;
    align-items: center;
    /* border: 1px solid #e0e0e0; */
    gap: 1em;
}

.simple-btn-topbar{
    display: block;
    /* margin-bottom: 1em; */
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
    transition: 0.3s ease;

}
.simple-btn-topbar:hover{
    transform: translateY(-2px);
}





/* src/App.css or appropriate CSS file */

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 10px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  /* The circle inside the slider */
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  /* When checked, move the slider */
  input:checked + .slider {
    background-color: #000000;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }



  .slider:before {
    position: absolute;
    content: "";
    height: 26px; /* Adjust as needed */
    width: 26px;  /* Adjust as needed */
    left: 4px;
    bottom: 4px;
    
    background-color: white;
    background-image: url('../../../public/opendyslexic.png'); /* Adjust the path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.4s;
    border-radius: 50%;
  }
  