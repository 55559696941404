/* .app {
    display: flex;
}


.main-app {

    transition: margin-left 0.3s ease;
} */

.mobile-display {
    display: none; /* Hidden by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f4f4; 

    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #333;
    text-align: center;
    padding: 20px;
    z-index: 10000;
  }
  

@media (max-width: 600px) {
    .mobile-display {
        display: flex; /* Show the mobile display message */
        flex-direction: column;
        /* justify-content: space-around; */
      }

      .displays{
        display: flex;
        flex-direction: row;
        font-size: 75px;
        gap: 0.25em;
        margin-bottom: 1em;
      }

      .text{
        line-height: 1.5em;
      }

      .ln-deductiv{
        color: #ffbb00;
        text-transform: uppercase;
        font-weight: 700;
      }

      .device{
        font-weight: 900;
        text-transform: uppercase;
      }
      
    
      .nav-panel,
      .rest {
        display: none; /* Hide navigation and main content */
      }
}

.app{
    display: flex;
   
    
}
.nav-panel {
    width: 12.5%;
    transition: width 0.3s ease;
}

.nav-panel.collapsed {
    width: 0;
    overflow: hidden;
    transition: width 0.3s ease;
}

.rest{
    flex: 1;
    /* border: 1px solid red; */
}